<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">
        {{ item.code }} -
        {{ item.type && config.TYPE_TEXT && config.TYPE_TEXT[item.type] || '' }}
      </span>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="closeDialog">
        Đóng
      </v-btn>
    </v-card-title>
    <v-card-text>
      <RequestMessages :item="item" :key="`msg_${item.id}`" />
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "Detail",
  components: {
    RequestMessages: () => import('@/components/internal_requests/Messages'),
  },
  props: {
    item: {
      type: Object,
      default: () => { }
    },
    config: {
      type: Object,
      default: () => { }
    },
  },
  data: () => ({
    isLoading: false,
    detail: {}
  }),
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
  },
}
</script>

<style scoped></style>
